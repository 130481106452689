<template>
  <div>
    <van-cell-group>
      <van-cell title="服务分类" :value="hzlist.SerTypeName" />
      <van-cell title="服务内容" :value="hzlist.SerContent" />
      <van-cell title="联系人" :value="hzlist.TouchMan" />
      <van-cell title="联系电话" :value="hzlist.TouchTel" />
      <van-cell title="收费标准" :value="hzlist.Charges" />
      <van-cell title="备注" :value="hzlist.Remark" />
    </van-cell-group>
    <div>
      <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
        <a :href="'tel:' + hzlist.TouchTel">
          <van-button
            round
            type="info"
            size="normal"
            style="text-center: right; font-size: 16px; width: 45%"
            color="#617bfa"
            >联系我们</van-button
          >
        </a>

        <van-button
          round
          type="primary"
          size="normal"
          style="text-center: right; font-size: 16px; width: 45%; float: right"
          @click="showPopup(hzlist)"
          >服务调查</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { WxGetProService } from "@/api/fangyi";
import { getwgToken } from "@/utils/auth";
export default {
  data() {
    return {
      listfrom: {},
      hzlist: [],
    };
  },
  created() {
    this.cyXq();
  },
  filters: {
    capitalizeTime: function (value) {
      if (!value) return "";
      let gstime = value.substring(0, 10);
      return gstime;
    },
  },
  methods: {
    // 获取服务详情
    cyXq() {
      console.log(this.$route);
      WxGetProService({ proSerId: this.$route.params.id })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.hzlist = {};
            } else {
              this.hzlist = res.data.data;
            }
          }
        })
        .catch(() => {});
    },
    showPopup: function(row){
        console.log(row.ProSerId);
        console.log(row.ProId);
        this.$router.push({
            path: "/wuye/wuyeList/wuyeXiangQing/fuwuXiangQing/proServiceAdd/" + row.ProSerId + '/' +row.ProId,
        });
    },
  },
};
</script>
<style scoped>
.van-cell {
  font-size: 15px !important;
}
.rightClass {
  width: 60% !important;
}
.wktime {
  padding: 0 20px;
}
.wktype {
  padding: 0 10px 10px;
}
.orginview .iconimg {
  width: 62%;
}
.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 12px 10px;
}
.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: "";
}
.listRid {
  /* background: rgb(245, 242, 242); */
  border-radius: 15px;
  /* box-shadow: 1px 1px 1px #cececc; */
  border: 1px solid #cececc;
  margin: 8px 0px;
  padding: 8px;
  font-size: 14px;
}
.listRid .listRidtitle {
  border-bottom: 1px solid #999;
  padding-bottom: 8px;
  font-size: 16px;
}
.listRidname {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}
.listRidAddress {
  padding: 3px 0px;
}
</style>